const $get = require('lodash.get')
const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

const isVisible = ({ context }) => {
  const { state } = context.$store
  const userSegments = $get(state.auth.user, 'protected.segments', [])

  const isAuhtorized = userSegments.some((segment) => {
    return segment.name === 'authorized'
  })

  return !!isAuhtorized
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    authMode: ['providers'],
    authOAuthProviders: [
      [
        'WSSOKeycloak',
        [
          {
            realm: 'PointP',
            clientId: 'vi-pointp',
            buttonText: 'Connexion POINT.P',
          },
        ],
      ],
    ],

    siteId: '66cee4b3e78d097a933aee02',
    enableExperimentalBlocks: false,
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    metadatas: {
      name: 'Radio POINT.P',
      colorPrimary: '#E75012',
      colorAccent: '#04AA6D',
      supportEmailAddress: 'support@bubblecast.co',
    },
    analytics: {
      matomo: '50',
    },
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '66f2bb78b44241b69a251252',
            i18n: {
              fr: {
                title: 'LE POINT PORTRAIT',
              },
            },
            heading: {
              color: '#FF9B2A',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
            },
            isVisible,
          },
          {
            type: 'tag',
            tagId: '66f2bba7b44241244c251263',
            i18n: {
              fr: {
                title: 'LE POINT PERSPECTIVES',
              },
            },
            heading: {
              color: '#AB2711',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
            },
            isVisible,
          },
          {
            type: 'tag',
            tagId: '66f2bbafb442419be1251269',
            i18n: {
              fr: {
                title: 'LE POINT PRODUIT',
              },
            },
            heading: {
              color: '#FF7911',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
            },
            isVisible,
          },
          {
            type: 'tag',
            tagId: '66f2bbc2b442417bc925126f',
            i18n: {
              fr: {
                title: 'LE POINT PRO',
              },
            },
            heading: {
              color: '#E75012',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
            },
            isVisible,
          },
          {
            type: 'tag',
            tagId: '66f2bbcab442418d92251275',
            i18n: {
              fr: {
                title: 'LE POINT PARTICULIERS',
              },
            },
            heading: {
              color: '#353535',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
            },
            isVisible,
          },
          {
            type: 'tag',
            tagId: '66f2bbd321b1dc0726752293',
            i18n: {
              fr: {
                title: 'LE POINT PLANÈTE',
              },
            },
            heading: {
              color: '#34D985',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
            },
            isVisible,
          },
          // {
          //   type: 'tag',
          //   tagId: '66f2bbdcb44241f88225127b',
          //   i18n: {
          //     fr: {
          //       title: 'LE POINT PARTENAIRES',
          //     },
          //   },
          //   heading: {
          //     color: '#E75012',
          //     dark: true,
          //     buttonColor: 'white',
          //     buttonLight: true,
          //     canRadio: false,
          //   },
          //   isVisible,
          // },
          {
            type: 'tag',
            tagId: '66f2bbe4b44241a495251281',
            i18n: {
              fr: {
                title: "LE POINT D'ESTELLE",
              },
            },
            heading: {
              color: '#D13915',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
            },
            isVisible,
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
